<template>
    <div id="portfolio">
        <div class="container">

            <h2 class="section--title" style="color: #494949;">Projects</h2>
        
            <div class="projects row" :class="{'mobile':mobile}">
                <div class="col-md-4" v-for="project in projectsListShown">
                    <div
                        class="clickableBox"
                        :class="{ 'emptyLink' : project.link == null }"
                        @click="project.link != null ? goToLink(project.link) : comingSoonMessage()"
                    >
                        <i class="fa-7x" :class="project.icon"></i>
                        <div>
                            <h3>{{ project.name }}</h3>
                        </div>
                    </div>
                </div>

                <div class="col-12" style="margin-bottom: 5em;">
                    <button class="btn shadow" @click="showHiddenProjects = !showHiddenProjects">
                        {{ showHiddenProjects ? 'Hide' : 'Show' }} Hidden Projects
                    </button>
                </div>
                
                <div id="hiddenParent" class="hiddenBox" :style="computedHiddenBoxStyle">

                    <transition name="hideSlide">
                        <div id="hiddenChild" v-if="showHiddenProjects">
                            <h2 class="section--title" style="color: #494949; margin-bottom: 5px;">Hidden Projects</h2>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">
                                    <h3 style="color: #a0a0a0; font-size: 1em; text-align: center; margin-bottom: 2em;">Projects that have very specific use case. Most of the projects under here typically only used once in it's lifetime.</h3>
                                </div>
                            </div>
        
                            <div class="row">
                                <div class="col-md-4" v-for="project in hiddenProjectsListShown">
                                    <div 
                                        class="clickableBox"
                                        :class="{ 'emptyLink' : project.link == null }"
                                        @click="project.link != null ? goToLink(project.link) : comingSoonMessage()"
                                    >
                                        <i class="fa-7x" :class="project.icon"></i>
                                        <div>
                                            <h3>{{ project.name }}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <h2 class="section--title" style="color: #494949; margin-top: 3em;">Portfolio</h2>

            <div class="portfolio row" v-if="portfolioData">
                <div class="col-md-6" v-for="singleData in portfolioData">
                    <silent-box
                        :gallery="singleData.gallery"
                        :preview-count="1"
                    ></silent-box>
                    <h3
                        class="mt-3"
                        :class="{'clickableLink' : singleData.link != null}"
                        @click="openNewPage(singleData.link)"
                    >{{ singleData.name }}</h3>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile : this.isMobile(),

                hiddenBoxHeight : '0px',
                showHiddenProjects : true,

                projectsList : [
                    {name:"Sudoku Solver", icon:"fas fa-game-board-alt", link:'/projects/sudoku-solver'},
                    {name:"Icon Generator", icon:"fas fa-icons", link:null},
                    {name:"QR Generator", icon:"fas fa-qrcode", link:null},
                    {name:"OpenAI Chat Prompt", icon:"fas fa-comments", link:null},
                    // {name:"OpenAI Chat Prompt", icon:"fas fa-comments", link:'/projects/chat'},
                    {name:"Logo Maker", icon:"fas fa-font", link:null},
                    {name:"Chart Generator", icon:"fas fa-chart-bar", link:'/projects/chart-generator'},
                ],

                hiddenProjectsList : [
                    {name:"ID Salted", icon:"fas fa-key-skeleton", link:'/projects/id-salt'},
                    {name:"Thumbnail Generator", icon:"fad fa-image-polaroid", link:'/projects/thumbnail-generator'},
                    {name:"Cube Solver", icon:"fas fa-cube", link:null},
                ],

                portfolioData : null,
                portfolioRawData : [
                    {
                        name : "QTrust",
                        link : "https://www.qtrust.id",
                        isMobileApp : false,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/qtrust0.png'),
                                description: 'QTrust Landing Page',
                                alt: 'QTrust Landing Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust1.png'),
                                description: 'Honda Sales Operation Dashboard',
                                alt: 'Honda Sales Operation Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust2.png'),
                                description: 'MPM Dealer Dashboard',
                                alt: 'MPM Dealer Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust3.png'),
                                description: 'New AHM Oil Dashboard',
                                alt: 'New AHM Oil Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust4.png'),
                                description: 'Pitch Dummy Dashboard',
                                alt: 'Pitch Dummy Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust5.png'),
                                description: 'BPH Migas Login Portal',
                                alt: 'BPH Migas Login Portal',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust6.png'),
                                description: 'Daihatsu Dashboard',
                                alt: 'Daihatsu Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/qtrust7.png'),
                                description: 'Old AHM Oil Dashboard',
                                alt: 'Old AHM Oil Dashboard',
                            },
                        ]
                    },
                    {
                        name : "Mykas Website",
                        link : "https://mykas.co.id",
                        isMobileApp : false,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/mykas1.png'),
                                description: 'Mykas Home Page',
                                alt: 'Mykas Home Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykas2.png'),
                                description: 'Employers Page',
                                alt: 'Employers Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykas3.png'),
                                description: 'Employees Page',
                                alt: 'Employees Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykas4.png'),
                                description: 'Learn Page',
                                alt: 'Learn Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykas5.png'),
                                description: 'Contact Page',
                                alt: 'Contact Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykas6.png'),
                                description: 'FAQ Page',
                                alt: 'FAQ Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasEmployer1.png'),
                                description: 'Login Page Dashboard',
                                alt: 'Login Page Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasEmployer2.png'),
                                description: 'Home Page Dashboard',
                                alt: 'Home Page Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasEmployer3.png'),
                                description: 'Kas Page Dashboard',
                                alt: 'Kas Page Dashboard',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasEmployer4.png'),
                                description: 'Calendar Page Dashboard',
                                alt: 'Calendar Page Dashboard',
                            },
                        ]
                    },
                    {
                        name : "SCA Eyelashes",
                        link : null,
                        isMobileApp : false,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/sca1.png'),
                                description: 'SCA Eyelashes Landing Page',
                                alt: 'SCA Eyelashes Landing Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca2.png'),
                                description: 'Profile Page',
                                alt: 'Profile Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca3.png'),
                                description: 'Product/Eyelashes Page',
                                alt: 'Product/Eyelashes Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca4.png'),
                                description: 'Other Products Page',
                                alt: 'Other Products Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca5.png'),
                                description: 'News & Events Page',
                                alt: 'News & Events Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca6.png'),
                                description: 'FAQ Page',
                                alt: 'FAQ Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/sca7.png'),
                                description: 'Certification Page',
                                alt: 'Certification Page',
                            },
                        ]
                    },
                    {
                        name : "Claim Port Dashboard",
                        link : null,
                        isMobileApp : false,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/cp2.png'),
                                description: 'Auction Page',
                                alt: 'Auction Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp1.png'),
                                description: 'Login Page',
                                alt: 'Login Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp3.png'),
                                description: 'Master Data Supplier Page',
                                alt: 'Master Data Supplier Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp4.png'),
                                description: 'Auction Detail Page',
                                alt: 'Auction Detail Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp5.png'),
                                description: 'Submit Auction',
                                alt: 'Submit Auction',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp6.png'),
                                description: 'Choose Auction',
                                alt: 'Choose Auction',
                            },
                            {
                                src: require('@/assets/images/Portfolio/cp7.png'),
                                description: 'Profile Settings Page',
                                alt: 'Profile Settings Page',
                            },
                        ]
                    },
                    {
                        name : "Perlu Dilindungi",
                        link : null,
                        isMobileApp : true,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/perlu1.jpeg'),
                                description: 'Disclaimer: Perlu Dilindungi is a replica of Peduli Lindungi App, built for practice and for fun. No harm was meant to be done.',
                                alt: 'Home Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/perlu2.jpeg'),
                                description: 'Disclaimer: Perlu Dilindungi is a replica of Peduli Lindungi App, built for practice and for fun. No harm was meant to be done.',
                                alt: 'Success Scan Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/perlu3.jpeg'),
                                description: 'Disclaimer: Perlu Dilindungi is a replica of Peduli Lindungi App, built for practice and for fun. No harm was meant to be done.',
                                alt: 'Test Result Page',
                            },
                        ]
                    },
                    {
                        name : "Mykas Mobile App",
                        link : null,
                        isMobileApp : true,
                        gallery : [
                            {
                                src: require('@/assets/images/Portfolio/mykasApp1.jpeg'),
                                description: 'Home Page',
                                alt: 'Home Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasApp2.jpeg'),
                                description: 'Cash Advance Page',
                                alt: 'Cash Advance Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasApp3.jpeg'),
                                description: 'Reimbursement Page',
                                alt: 'Reimbursement Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasApp4.jpeg'),
                                description: 'Asset Peminjaman Page',
                                alt: 'Asset Peminjaman Page',
                            },
                            {
                                src: require('@/assets/images/Portfolio/mykasApp5.jpeg'),
                                description: 'Profile Page',
                                alt: 'Profile Page',
                            },
                        ]
                    },
                ],
            }
        },
        mounted () {
            //Load The Height of Child First
            // this.loadHiddenBoxHeight();
            this.setupData();

            //Close the hidden box next tick
            this.$nextTick(() => {
            this.loadHiddenBoxHeight();
                this.showHiddenProjects = false;
            });
        },
        computed : {
            //Only show projects that is clickable on mobile
            projectsListShown () {
                return this.mobile ? this.projectsList.filter((project) => {
                    return project.link != null;
                }) : this.projectsList;
            },
            
            //Only show projects that is clickable on mobile
            hiddenProjectsListShown () {
                return this.mobile ? this.hiddenProjectsList.filter((project) => {
                    return project.link != null;
                }) : this.hiddenProjectsList;
            },
            computedHiddenBoxStyle () {
                const newStyle = {'height': this.showHiddenProjects ? this.hiddenBoxHeight : '0px'};
                return newStyle;
            }
        },
        methods : {
            setupData () {
                let result = this.portfolioRawData;

                for (var i in result) {
                    for (var j in result[i].gallery) {
                        if (this.mobile) {
                            if (result[i].isMobileApp) result[i].gallery[j]['thumbnailHeight'] = '335px';
                            else result[i].gallery[j]['thumbnailWidth'] = '100%';
                        } else
                            result[i].gallery[j]['thumbnailHeight'] = '335px';
                    }
                }

                this.portfolioData = result;
            },
            openNewPage (link) {
                window.open(link, '_blank');
            },
            goToLink (link) {
                this.$router.push(link);
            },
            comingSoonMessage () {
                this.$swal.fire({
                    title : 'Coming Soon',
                    text: 'Good things are coming!',
                    icon : 'warning'
                })
            },
            loadHiddenBoxHeight () {
                var child = document.getElementById('hiddenChild');
                this.hiddenBoxHeight = child.offsetHeight + 'px';
            }
        }
    }
</script>

<style scoped>
    #portfolio {
        background: white;
        color: black;

        padding: 5em 0 3em;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #portfolio i,
    #portfolio h3,
    #portfoliio p {
        color: #494949;
    }

    #portfolio .projects i {
        margin-bottom: 20px;
    }

    .clickableBox {
        display: inline-block;
        cursor: pointer;


        /* border: 1px solid grey; */
        box-shadow: 0px 0px 20px -5px grey;
        border-radius: 15px;

        padding: 50px 50px 25px 50px;
        margin-bottom: 5em;

        position: relative;
    }

    .clickableBox h3 {
        margin: 0;
    }

    .clickableLink {
        cursor: pointer;
        text-decoration: underline 0.15em rgba(0, 0, 0, 0);
        transition: text-decoration-color 300ms;
    }

    .clickableLink:hover {
        text-decoration-color: rgba(0, 0, 0, 1);
    }


    

    #portfolio .projects.mobile .clickableBox {
        width: 280px;
        padding: 50px 40px 30px 40px;
    }

    #portfolio .projects:not(.mobile) .clickableBox {
        transition: transform 0.3s;
    }

    .projects .clickableBox.emptyLink {
        background: #d8d8d8;
    }

    #portfolio .projects:not(.mobile) .clickableBox:not(.emptyLink):hover {
        transition: transform 0.5s;
        transform: scale(1.1);
    }

    #portfolio .projects:not(.mobile) .clickableBox>div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 20px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: opacity .5s;
        opacity: 0;
    }

    #portfolio .projects:not(.mobile) .clickableBox>div h3 {
        width: 80%;
        color: black;
        font-weight: bold;
    }

    #portfolio .projects:not(.mobile) .clickableBox:hover>div {
        transition: opacity .5s;
        opacity: 1;
    }




    #portfolio .portfolio.row>div {
        padding: 40px;
    }

    .hideSlide-enter-active, .hideSlide-leave-active {
        transition: opacity .5s, transform .7s;
    }
    .hideSlide-enter, .hideSlide-leave-to /* .hideSlide-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(-100px);
    }

    .hiddenBox {
        /* height: 0px; */
        transition: height .7s ease;
    }

    /* .hiddenBox-shown {
        height: 500px;
    } */
</style>
<template>
    <div>
        <div ref="pieChart" style="min-height: 500px;"></div>
    </div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                deep : true,
                handler : 'getData',
            },
        },
        methods : {
            getData () {
                let length = this.chart.data.length;
                this.chart.addData(this.data ? Object.entries(this.data).map(([key, value]) => ({key,value})) : [{key:"No Data", value:1}], length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.pieChart, am4charts.PieChart);

                // Add and configure Series
                let pieSeries = this.chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "value";
                pieSeries.dataFields.category = "key";
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;

                // This creates initial animation
                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                this.chart.exporting.menu = new am4core.ExportMenu();
            }
        }
    }
</script>
<template>
    <div id="homePage" :class="{'mobile' : mobile}">

        <!-- Content Sections Start -->
        <landing></landing>
        <about-me></about-me>
        <portfolio></portfolio>
        <gallery></gallery>
        <contact></contact>
        <!-- Content Sections End -->

        <!-- Sidemenu Start -->
        <div class="show-menu" v-if="mobile" @click="showMenuMobile = !showMenuMobile">
            <i class="fas fa-bars"></i>
        </div>
        <transition name="slide-fade">
            <nav v-if="!mobile || showMenuMobile" id="sidemenu" :class="{'sidemenu-mobile' : mobile}">
                <ul>
                    <li @click="goToSection('landing'); showMenuMobile = false;">
                        <i class="fas fa-home"></i>
                    </li>
                    <li @click="goToSection('aboutMe'); showMenuMobile = false;">
                        <i class="fas fa-user-astronaut"></i>
                    </li>
                    <li @click="goToSection('portfolio'); showMenuMobile = false;">
                        <i class="fas fa-clipboard-list-check"></i>
                    </li>
                    <li @click="goToSection('gallery'); showMenuMobile = false;">
                        <i class="fas fa-image"></i>
                    </li>
                    <li @click="goToSection('contact'); showMenuMobile = false;">
                        <i class="fas fa-at"></i>
                    </li>
                </ul>
            </nav>
        </transition>
        <!-- Sidemenu End -->
        
    </div>
</template>

<script>
    import Landing from './HomePage/Landing.vue'
    import AboutMe from './HomePage/AboutMe.vue'
    import Portfolio from './HomePage/Portfolio.vue'
    import Gallery from './HomePage/Gallery.vue'
    import Contact from './HomePage/Contact.vue'

    export default {
        name: 'Home',
        data() {
            return {
                mobile : this.isMobile(),
                showMenuMobile : false,
            }
        },
        components: {
            Landing,
            AboutMe,
            Portfolio,
            Gallery,
            Contact,
        },
        mounted() {
            // this.startJobTitleCycle();
        },
        methods: {
        }
    }
</script>

<style>
    *:not(i) {
        font-family: Saira;
        color: #F0F0F0;
    }

    .section--title {
        font-size: 3em;
        margin-bottom: 2em;
        color: white;
    }

    .slide-fade-enter-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translate(-20px, -50%) !important;
        opacity: 0;
    }
</style>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@500&family=Rock+Salt&family=Saira:wght@500&display=swap');

    #homePage {
        height: 100vh;
        overflow-y: auto;
        background: #7F7F7F;
        text-align: center;
    }

    #homePage:not(.mobile)::-webkit-scrollbar{
        width: 0.75rem;
    }
    #homePage:not(.mobile)::-webkit-scrollbar-track{
        background-color: #626262;
    }
    #homePage:not(.mobile)::-webkit-scrollbar-thumb{
        background-color:#E0E0E0;
        border-radius: 20px;
    }

    /* SIDEMENU START */
    .show-menu {
        position: absolute;
        top: 0;
        width: 70px;
        background-color: #494949;
        border-bottom-right-radius: 20px;
    }

    #sidemenu {
        font-family: "Font Awesome 5 Pro" !important;
        position: fixed;
        top: 30%;
        transform: translateY(-50%);
    }

    #sidemenu.sidemenu-mobile {
        top: 50%;
    }

    #sidemenu>i {
        cursor: pointer;
    }

    nav {
        float: left;
        top: 0;
        left: 0;
        background: transparent;
    }

    nav ul {
        text-align: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    nav ul li {
        position: relative;
        width: 70px;
        cursor: pointer;
        background: #626262;
        text-transform: uppercase;
        transition:all .4s ease-out;
    }

    nav ul li:after {
        position: absolute;
        background: white;
        color: #626262;
        top:0;
        left: 70px;
        width: 70px; height: 100%;
    opacity:.5;
    transform: perspective(400px) rotateY(90deg);
        transform-origin: 0 100%;
        transition:all .4s ease-out;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    nav ul li:nth-child(1):after { 
        content: "Home";
        /* line-height: 88px; */
    }
    nav ul li:nth-child(2):after { 
        content: "About";
        /* line-height: 88px; */
    }
    nav ul li:nth-child(3):after { 
        content: "Work";
        /* line-height: 85px; */
    }
    nav ul li:nth-child(4):after { 
        content: "Gallery";
        /* line-height: 70px; */
    }
    nav ul li:nth-child(5):after { 
        content: "Say hi";
        /* line-height: 70px; */
    }

    nav ul li:hover {
        transform: translateX(-70px);
    }

    nav ul li:hover:after {
    opacity: 1;
        transform: perspective(400px) rotateY(0deg) scale(1) ;
    }


    nav ul li > div {
        display: inline-block;
        padding: 25px 0;
        background: transparent;
    }

    nav ul li div { position: relative; }

    .show-menu i,
    nav ul li i {
        padding: 1rem 0;
        font-size: 2.5em;
    }
    /* SIDEMENU END */

</style>
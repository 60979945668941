<template>
    <div id="thumbnail-generator">
        <div class="container mt-5" style="display: flex;" @click="$router.go(-1)">
            <button class="btn btn-outline-secondary"><i class="fas fa-chevron-left"></i> Home</button>
        </div>
        <h1>Thumbnail Generator</h1>
        
        <!-- <div class="mt-5" style="display: flex; justify-content: center;">
            <div class="thumbnail--box">
                <div class="thumbnail--content">

                </div>
            </div>
        </div> -->

        <div>
            <!-- <input type="text" v-model="heroTitle"> -->
        </div>

        <div class="mt-5 mb-4" style="display: flex; flex-direction: row; justify-content: center;">

            <!-- Thumbnail Start -->
            <div class="thumbnail--box2" ref="thumbnailBox">
                <div class="title--box">
                    <h1
                        style="
                        white-space:pre-wrap;
                        width: 75%;
                        margin: 0;
                        color: black;
                        font-weight: bold;
                        font-family: sans-serif;"
                        :style="heroFontSize"
                    >{{ heroTitle }}</h1>
                </div>
                <div class="item--box">
                    <img v-if="fileImage" :src="fileImage" style="max-height: 100%; max-width: 100%;">
                    <img v-else src="@/assets/images/item1.jpg" style="max-height: 100%; max-width: 100%;">
                </div>
                <div class="watermark--box">
                    <span>@beepo_babyshop</span>
                </div>
            </div>
            <!-- Thumbnail End -->

            <div style="display: flex; flex-direction: column; justify-content: center; margin-left: 50px; align-items: flex-start;">
                <label class="form-label">Image</label>
                <div
                    class="border"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                    style="display: flex; flex-direction: column; padding: 20px 50px; cursor: pointer;"
                >
                    <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle" 
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        style="height: 0; width: 0"
                    />
                  
                    <label for="assetsFieldHandle" class="block cursor-pointer">
                      <div style="text-align: center">
                        <h3 class="m-0" style="color: grey;  cursor: pointer;" v-if="this.filelist.length <= 0">
                            Upload Image Here
                        </h3>
                      </div>
                    </label>
                    <div v-if="this.filelist.length" v-cloak>
                      <p class="text-sm m-0" v-for="file in filelist">
                        {{ file.name }}
                      </p>
                      <button class="btn btn-outline-secondary pl-2" type="button" @click="remove(filelist.indexOf(file))" title="Remove file">Remove</button>
                    </div>
                </div>
                <br>
                <label class="form-label">Title</label>
                <textarea v-model="heroTitle" style="width: 100%;" placeholder="Enter Title Here"></textarea>
                <br>
                <div class="text-center w-100">
                    <button class="btn btn-info" @click="savePhoto">Save Photo</button>
                </div>
            </div>
        </div>


        <div style="width: 0; height: 0; overflow: hidden;">
            <img :src="output">
        </div>

    </div>
</template>

<script>
    // import 'xlsx-to-json';

    import xlsx from 'xlsx';

    export default {
        data () {
            return {
                isLoading : false,

                delimeterOptions : [',', ';', '|'],
                delimeterSelected : ',',

                data : null,
                keys : null,
                selected : [],
                
                heroTitle : "Hello World!",

                output: "",
                filelist: [], // Store our uploaded files
                fileImage: null,
            }
        },
        components : {

        },
        mounted () {

        },
        computed : {
            heroFontSize () {
                let fontSize = "6em";
                let wordsList = this.heroTitle.split("\n");
                let numWords = 0;

                for (var i = 0; i < wordsList.length; i++) if (wordsList[i].length > numWords) numWords = wordsList[i].length;

                if ((numWords >= 1) && (numWords < 10)) {
                    fontSize = "6em";
                }
                else if ((numWords >= 10) && (numWords < 20)) {
                    fontSize = "4em";
                }
                else if ((numWords >= 20) && (numWords < 30)) {
                    fontSize = "2.5em";
                }
                else {
                    fontSize = "2em";
                }

                return {"font-size" : fontSize}
            }
        },
        methods : {
            async savePhoto () {
                console.log('%cProjectsThumbnailGenerator.vue line:95 hello world!', 'color: #007acc;');
                let el = this.$refs.thumbnailBox;
                let options = { type: "dataURL" };
                this.output = await this.$html2canvas(el, options);
                // const blob = new Blob([await this.$html2canvas(el, options)], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = this.output
                link.download = `${this.heroTitle}.png`
                link.click()
                URL.revokeObjectURL(link.href)
            },
            async uploadXlsxFile () {
                const { value: file } = await this.$swal.fire({
                    title: 'Upload File',
                    text : 'Only upload .xlsx file',
                    input: 'file',
                    icon : 'info',
                    inputAttributes: {
                    'accept' : '.xlsx',
                    'aria-label' : 'Upload your file'
                    }
                })

                if (file) {
                    this.previewFiles(file);
                    // var reader = new FileReader();
                    // reader.onload = (e) => {
                    //     //Loading Started
                    //     this.isLoading = true;

                    //     // console.log(this.csvToJson(e.target.result));
                    //     //Split Lines
                    //     // let dataLines = e.target.result.split(/[\r\n]+/g);

                    //     // reader.readAsText(e.target.result);
                    //     // xlsxj = require("xlsx-to-json");
                    //     // xlsxj({
                    //     //     input: e.target.result, 
                    //     //     // output: "output.json"
                    //     // }, function(err, result) {
                    //     //     if(err) {
                    //     //     console.error(err);
                    //     //     }else {
                    //     //     console.log('%cProjectsIdSalt.vue line:111 result', 'color: #007acc;', result);
                    //     //     console.log(result);
                    //     //     }
                    //     // });

                    //     //Loading Ends
                    //     this.isLoading = false;
                    // }
                }
            },
            previewFiles(e) {
                // var files = e.target.files, f = files[0];

                var reader = new FileReader();
                reader.onload = (e) => {
                    var data = new Uint8Array(e.target.result);
                    var workbook = xlsx.read(data, {type: 'array'});
                    let sheetName = workbook.SheetNames[0]
                    /* DO SOMETHING WITH workbook HERE */
                    console.log('%cProjectsIdSalt.vue line:131 workbook', 'color: #007acc;', workbook);
                    let worksheet = workbook.Sheets[sheetName];
                    // console.log('%cProjectsIdSalt.vue line:133 xlsx.utils.sheet_to_json(worksheet)', 'color: #007acc;', xlsx.utils.sheet_to_json(worksheet));
                    let allData = xlsx.utils.sheet_to_json(worksheet);
                    console.log('%cProjectsIdSalt.vue line:138 "hello"', 'color: #007acc;', "hello");

                    let uniqueDictionary = [];
                    let filteredData = [];
                    console.log('%cProjectsIdSalt.vue line:141 allData.length', 'color: #007acc;', allData.length);

                    for (var i = 0; i < allData.length; i++) {
                        //Salt Hash Here


                        if (uniqueDictionary.indexOf(allData[i]['Nama']+allData[i]['PT']) == -1) {
                            uniqueDictionary.push(allData[i]['Nama']+allData[i]['PT']);
                            let singleData = allData[i];
                            let newIdentifier = this.randomUniqueIdentifier()
                            singleData['Password'] = allData[i]['ID Kegiatan'] + newIdentifier;
                            filteredData.push(singleData);
                        }
                        
                    }
                    console.log('%cProjectsIdSalt.vue line:151 filteredData.length', 'color: #007acc;', filteredData.length);

                    this.exportExcel(filteredData);

                    // console.log('%cProjectsIdSalt.vue line:150 allData.splice(0, 10)', 'color: #007acc;', allData.splice(0, 10));
                };
                reader.readAsArrayBuffer(e);
            },


            onChange() {
                this.filelist = [...this.$refs.file.files];
                const file = this.$refs.file.files[0];
                this.fileImage = URL.createObjectURL(file);
            },
            remove(i) {
                this.filelist.splice(i, 1);
                this.fileImage = null;
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('border-success')) {
                event.currentTarget.classList.remove('border-secondary');
                event.currentTarget.classList.add('border-success');
                }
            },
            dragleave(event) {
                // Clean up
                event.currentTarget.classList.add('border-secondary');
                event.currentTarget.classList.remove('border-success');
            },
            drop(event) {
                event.preventDefault();
                this.$refs.file.files = event.dataTransfer.files;
                this.onChange(); // Trigger the onChange event manually
                // Clean up
                event.currentTarget.classList.add('border-secondary');
                event.currentTarget.classList.remove('border-success');
            }
        }
    }
</script>

<style>
    
</style>

<style scoped>
    * {
        --box-size: 1000px;
    }

    #thumbnail-generator *{
        color: black;
        text-align: center;
    }
    
    .thumbnail--box {
        width: var(--box-size);
        height: var(--box-size);

        background: #85e8d4;
        padding: 1.75%;

        /* border: 1.75% solid #85e8d4; */
        /* border-radius: 20px; */

        /* background-color: cyan; */
    }

    .thumbnail--content {
        background-color: white;
        width: 100%;
        height: 100%;
    }



    .thumbnail--box2 {
        width: 900px;
        height: 900px;
        /* background: url("~@/assets/images/Thumbnail Background/beepo1.png"); */
        /* background: url("~@/assets/images/Thumbnail Background/beepo2.png"); */
        background: url("~@/assets/images/Thumbnail Background/beepo3.png");

        text-align: left;
        padding: 1em;

        position: relative;
    }

    .title--box {
        height: 22%;
        display: flex;
        align-items: center;
        padding-left: 5%;
    }

    .item--box {
        position: absolute;

        bottom: 1em;
        right: 1em;

        width: 75%;
        height: 75%;

        display: flex;
        justify-content: center;
        align-items: center;

        /* background-color: pink; */

        padding: 1em;
    }

    .watermark--box {
        z-index: 2;

        position: absolute;

        bottom: 2em;
        right: 2em;
    }

    .watermark--box span {
        color: grey !important;
        font-size: 1.1em;
    }
</style>
<template>
    <div id="chart-generator">
        <h1 class="mt-5">ID Salted</h1>
        <div class="row mt-5">
            <div class="col-md-4 offset-md-4">
                <h3>Upload XLSX Data</h3>
                <button
                    @click="uploadXlsxFile"
                    :disabled="isLoading"
                    class="btn shadow rounded mt-3"
                    type="button"
                    variant="secondary"
                    style="min-height: 40px;"
                >
                    <span class="fa fa-file-upload"></span>&nbsp;&nbsp;Upload .xlsx File
                </button>
            </div>
        </div>

        <div class="mt-5" v-if="data != null">
            <h4><span class="rainbow rainbow_text_animated">{{ numberWithCommas(data.length) }}</span> rows has been successfully imported!</h4>

            <div class="container">
                <h5 class="mt-5">Choose Columns:</h5>
                <label
                    v-for="singleColumn, i in keys"
                    :for="'btn'+i"
                    class="btn btn-default shadow rounded"
                    style="margin: 20px;"
                >{{ toCapitalizedWords(singleColumn) }}
                    <input
                        type="checkbox"
                        :id="'btn'+i"
                        :value="singleColumn"
                        class="badgebox"
                        v-model="selected"
                    >
                    <span class="badge">&check;</span>
                </label>
            </div>

            <div class="container">
                <div v-for="singleSelected in selected">
                    <chart-generator
                        :title="singleSelected"
                        :data="data.map(x=>x[singleSelected]).reduce(function (acc, curr) {
                            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
                          }, {})"
                    ></chart-generator>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    // import 'xlsx-to-json';

    import chartGenerator from './children/ChartGenerator'
    import xlsx from 'xlsx';

    export default {
        data () {
            return {
                isLoading : false,

                delimeterOptions : [',', ';', '|'],
                delimeterSelected : ',',

                data : null,
                keys : null,
                selected : [],
            }
        },
        components : {
            chartGenerator
        },
        mounted () {

        },
        methods : {
            async uploadXlsxFile () {
                const { value: file } = await this.$swal.fire({
                    title: 'Upload File',
                    text : 'Only upload .xlsx file',
                    input: 'file',
                    icon : 'info',
                    inputAttributes: {
                    'accept' : '.xlsx',
                    'aria-label' : 'Upload your file'
                    }
                })

                if (file) {
                    this.previewFiles(file);
                    // var reader = new FileReader();
                    // reader.onload = (e) => {
                    //     //Loading Started
                    //     this.isLoading = true;

                    //     // console.log(this.csvToJson(e.target.result));
                    //     //Split Lines
                    //     // let dataLines = e.target.result.split(/[\r\n]+/g);

                    //     // reader.readAsText(e.target.result);
                    //     // xlsxj = require("xlsx-to-json");
                    //     // xlsxj({
                    //     //     input: e.target.result, 
                    //     //     // output: "output.json"
                    //     // }, function(err, result) {
                    //     //     if(err) {
                    //     //     console.error(err);
                    //     //     }else {
                    //     //     console.log('%cProjectsIdSalt.vue line:111 result', 'color: #007acc;', result);
                    //     //     console.log(result);
                    //     //     }
                    //     // });

                    //     //Loading Ends
                    //     this.isLoading = false;
                    // }
                }
            },
            previewFiles(e) {
                // var files = e.target.files, f = files[0];

                var reader = new FileReader();
                reader.onload = (e) => {
                    var data = new Uint8Array(e.target.result);
                    var workbook = xlsx.read(data, {type: 'array'});
                    let sheetName = workbook.SheetNames[0]
                    /* DO SOMETHING WITH workbook HERE */
                    console.log('%cProjectsIdSalt.vue line:131 workbook', 'color: #007acc;', workbook);
                    let worksheet = workbook.Sheets[sheetName];
                    // console.log('%cProjectsIdSalt.vue line:133 xlsx.utils.sheet_to_json(worksheet)', 'color: #007acc;', xlsx.utils.sheet_to_json(worksheet));
                    let allData = xlsx.utils.sheet_to_json(worksheet);
                    console.log('%cProjectsIdSalt.vue line:138 "hello"', 'color: #007acc;', "hello");

                    let uniqueDictionary = [];
                    let filteredData = [];
                    console.log('%cProjectsIdSalt.vue line:141 allData.length', 'color: #007acc;', allData.length);

                    for (var i = 0; i < allData.length; i++) {
                        //Salt Hash Here


                        if (uniqueDictionary.indexOf(allData[i]['Nama']+allData[i]['PT']) == -1) {
                            uniqueDictionary.push(allData[i]['Nama']+allData[i]['PT']);
                            let singleData = allData[i];
                            let newIdentifier = this.randomUniqueIdentifier()
                            singleData['Password'] = allData[i]['ID Kegiatan'] + newIdentifier;
                            filteredData.push(singleData);
                        }
                        
                    }
                    console.log('%cProjectsIdSalt.vue line:151 filteredData.length', 'color: #007acc;', filteredData.length);

                    this.exportExcel(filteredData);

                    // console.log('%cProjectsIdSalt.vue line:150 allData.splice(0, 10)', 'color: #007acc;', allData.splice(0, 10));
                };
                reader.readAsArrayBuffer(e);
            },
            randomUniqueIdentifier () {
                let allChars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
                let compiledChars = "";
                for (var i = 0; i < 5; i++) {
                    compiledChars = compiledChars + allChars[this.randomInt(0, allChars.length)]
                }
                console.log('%cProjectsIdSalt.vue line:153 compiledChars', 'color: #007acc;', compiledChars);
                return compiledChars;
            }
        }
    }
</script>

<style>


    .rainbow {
        text-align: center;
        text-decoration: underline;
        /* font-size: 32px; */
        font-family: monospace;
        letter-spacing: 5px;
    }

    .rainbow_text_animated {
        /* background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff) d!important; */
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        animation: rainbow_animation 6s ease-in-out infinite;
        background-size: 400% 100%;
    }

    @keyframes rainbow_animation {
        0%,100% {
            background-position: 0 0;
        }

        50% {
            background-position: 100% 0;
        }
    }
</style>

<style scoped>
    #chart-generator *{
        color: black;
        text-align: center;
    }

    /* Hiding the checkbox, but allowing it to be focused */
    .badgebox
    {
        opacity: 0;
    }

    .badgebox + .badge
    {
        /* Move the check mark away when unchecked */
        text-indent: -999999px;
        /* Makes the badge's width stay the same checked and unchecked */
        width: 27px;
    }

    .badgebox:focus + .badge
    {
        /* Set something to make the badge looks focused */
        /* This really depends on the application, in my case it was: */
        
        /* Adding a light border */
        box-shadow: inset 0px 0px 5px;
        /* Taking the difference out of the padding */
    }

    .badgebox:checked + .badge
    {
        /* Move the check mark back when checked */
        text-indent: 0;
    }



    .delimeterSelectionItem {
        width: 50px;
        height: 50px;
        border-radius: 1000px;
        border:1px solid grey;

        margin: 0 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: background 0.5s ease;
    }

    .delimeterSelectionItem:not(.selected) {
        cursor: pointer;
    }

    .delimeterSelectionItem.selected {
        background:black;
    }

    .delimeterSelectionItem span {
        color: grey;
        font-size: 20px;
    }

    .delimeterSelectionItem.selected span {
        color: white !important;
    }
</style>
<template>
    <div id="chart-generator">
        <h1 class="mt-5">Chart Generator</h1>
        <div class="row mt-5">
            <div class="col-md-4 offset-md-4">
                <h3>Upload CSV Data</h3>
                <div style="display: flex; align-items: center; justify-content: center;">
                    <div v-for="singleDelimeter in delimeterOptions">
                        <div
                            class="delimeterSelectionItem"
                            :class="{'selected':delimeterSelected==singleDelimeter}"
                            title="Select Delimeter"
                            @click="delimeterSelected = singleDelimeter"
                        >
                            <span>{{singleDelimeter}}</span>
                        </div>
                    </div>
                </div>
                <button
                    @click="uploadCsvFile"
                    :disabled="isLoading"
                    class="btn shadow rounded mt-3"
                    type="button"
                    variant="secondary"
                    style="min-height: 40px;"
                >
                    <span class="fa fa-file-upload"></span>&nbsp;&nbsp;Upload .csv File
                </button>
            </div>
        </div>

        <div class="mt-5" v-if="data != null">
            <h4><span class="rainbow rainbow_text_animated">{{ numberWithCommas(data.length) }}</span> rows has been successfully imported!</h4>

            <div class="container">
                <h5 class="mt-5">Choose Columns:</h5>
                <label
                    v-for="singleColumn, i in keys"
                    :for="'btn'+i"
                    class="btn btn-default shadow rounded"
                    style="margin: 20px;"
                >{{ toCapitalizedWords(singleColumn) }}
                    <input
                        type="checkbox"
                        :id="'btn'+i"
                        :value="singleColumn"
                        class="badgebox"
                        v-model="selected"
                    >
                    <span class="badge">&check;</span>
                </label>
            </div>

            <div class="container">
                <div v-for="singleSelected in selected">
                    <chart-generator
                        :title="singleSelected"
                        :data="data.map(x=>x[singleSelected]).reduce(function (acc, curr) {
                            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
                          }, {})"
                    ></chart-generator>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import chartGenerator from './children/ChartGenerator'

    export default {
        data () {
            return {
                isLoading : false,

                delimeterOptions : [',', ';', '|'],
                delimeterSelected : ',',

                data : null,
                keys : null,
                selected : [],
            }
        },
        components : {
            chartGenerator
        },
        mounted () {

        },
        methods : {
            async uploadCsvFile () {
                const { value: file } = await this.$swal.fire({
                    title: 'Upload File',
                    text : 'Only upload .csv file',
                    input: 'file',
                    icon : 'info',
                    inputAttributes: {
                    'accept' : '.csv',
                    'aria-label' : 'Upload your file'
                    }
                })

                if (file) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        //Loading Started
                        this.isLoading = true;

                        // console.log(this.csvToJson(e.target.result));
                        //Split Lines
                        let dataLines = e.target.result.split(/[\r\n]+/g);

                        this.keys = dataLines[0].split(this.delimeterSelected);

                        //Convert From Arrays To Objects
                        let data = [];
                        for (var i = 1; i < dataLines.length; i++) data.push(this.keys.reduce((obj, key, index) => ({ ...obj, [key]: dataLines[i].split(this.delimeterSelected)[index] }), {}));

                        this.data = data;
                        console.log(data);

                        //Loading Ends
                        this.isLoading = false;
                    }
                    reader.readAsText(file);
                }
            },
        }
    }
</script>

<style>


    .rainbow {
        text-align: center;
        text-decoration: underline;
        /* font-size: 32px; */
        font-family: monospace;
        letter-spacing: 5px;
    }

    .rainbow_text_animated {
        /* background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff) d!important; */
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        animation: rainbow_animation 6s ease-in-out infinite;
        background-size: 400% 100%;
    }

    @keyframes rainbow_animation {
        0%,100% {
            background-position: 0 0;
        }

        50% {
            background-position: 100% 0;
        }
    }
</style>

<style scoped>
    #chart-generator *{
        color: black;
        text-align: center;
    }

    /* Hiding the checkbox, but allowing it to be focused */
    .badgebox
    {
        opacity: 0;
    }

    .badgebox + .badge
    {
        /* Move the check mark away when unchecked */
        text-indent: -999999px;
        /* Makes the badge's width stay the same checked and unchecked */
        width: 27px;
    }

    .badgebox:focus + .badge
    {
        /* Set something to make the badge looks focused */
        /* This really depends on the application, in my case it was: */
        
        /* Adding a light border */
        box-shadow: inset 0px 0px 5px;
        /* Taking the difference out of the padding */
    }

    .badgebox:checked + .badge
    {
        /* Move the check mark back when checked */
        text-indent: 0;
    }



    .delimeterSelectionItem {
        width: 50px;
        height: 50px;
        border-radius: 1000px;
        border:1px solid grey;

        margin: 0 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: background 0.5s ease;
    }

    .delimeterSelectionItem:not(.selected) {
        cursor: pointer;
    }

    .delimeterSelectionItem.selected {
        background:black;
    }

    .delimeterSelectionItem span {
        color: grey;
        font-size: 20px;
    }

    .delimeterSelectionItem.selected span {
        color: white !important;
    }
</style>
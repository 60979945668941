<template>
    <div>
        <div ref="columnChart" style="min-height: 1000px;"></div>
    </div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                deep : true,
                handler : 'getData',
            },
        },
        methods : {
            getData () {
                let length = this.chart.data.length;
                this.chart.addData(this.data ? Object.entries(this.data).map(([key, value]) => ({key,value})) : [{key:"No Data", value:1}], length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                // Create chart instance
                this.chart = am4core.create(this.$refs.columnChart, am4charts.XYChart);
                this.chart.scrollbarX = new am4core.Scrollbar();

                // Add data
                this.chart.data = [{
                "key": "USA",
                "value": 3025
                }, {
                "key": "China",
                "value": 1882
                }, {
                "key": "Japan",
                "value": 1809
                }, {
                "key": "Germany",
                "value": 1322
                }, {
                "key": "UK",
                "value": 1122
                }, {
                "key": "France",
                "value": 1114
                }, {
                "key": "India",
                "value": 984
                }, {
                "key": "Spain",
                "value": 711
                }, {
                "key": "Netherlands",
                "value": 665
                }, {
                "key": "Russia",
                "value": 580
                }, {
                "key": "South Korea",
                "value": 443
                }, {
                "key": "Canada",
                "value": 441
                }];

                // Create axes
                let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "key";
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 30;
                categoryAxis.renderer.labels.template.horizontalCenter = "right";
                categoryAxis.renderer.labels.template.verticalCenter = "middle";
                categoryAxis.renderer.labels.template.rotation = 270;
                categoryAxis.tooltip.disabled = true;
                categoryAxis.renderer.minHeight = 110;

                let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.renderer.minWidth = 50;

                // Create series
                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.sequencedInterpolation = true;
                series.dataFields.valueY = "value";
                series.dataFields.categoryX = "key";
                series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
                series.columns.template.strokeWidth = 0;

                series.tooltip.pointerOrientation = "vertical";

                series.columns.template.column.cornerRadiusTopLeft = 10;
                series.columns.template.column.cornerRadiusTopRight = 10;
                series.columns.template.column.fillOpacity = 0.8;

                // on hover, make corner radiuses bigger
                let hoverState = series.columns.template.column.states.create("hover");
                hoverState.properties.cornerRadiusTopLeft = 0;
                hoverState.properties.cornerRadiusTopRight = 0;
                hoverState.properties.fillOpacity = 1;

                series.columns.template.adapter.add("fill", (fill, target) => {
                    return this.chart.colors.getIndex(target.dataItem.index);
                });

                // Cursor
                this.chart.cursor = new am4charts.XYCursor();
                this.chart.exporting.menu = new am4core.ExportMenu();

                categoryAxis.sortBySeries = series;

            }
        }
    }
</script>
<template>
    <div>
        <div ref="barChart" style="min-height: 500px; max-height: 2000px;" :style="{height: calculatedHeight + 'px'}"></div>
    </div>
</template>

<script>
    /* Imports */
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    export default {
        data () {
            return {
                chart : null,
            }
        },
        props : ['data'],
        mounted () {
            this.createChart();
            this.getData();
        },
        watch : {
            'data' : {
                deep : true,
                handler : 'getData',
            },
        },
        computed : {
            calculatedHeight () {
                // console.log('%cbarChart.vue line:32 this.data.length', 'color: #007acc;', this.data.length);
                return Object.keys(this.data).length * 100;
            }
        },
        methods : {
            getData () {
                let length = this.chart.data.length;
                this.chart.addData(this.data ? Object.entries(this.data).map(([key, value]) => ({key,value})) : [{key:"No Data", value:1}], length);
            },
            createChart () {
                /* Chart code */
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // Themes end

                this.chart = am4core.create(this.$refs.barChart, am4charts.XYChart);
                this.chart.padding(40, 40, 40, 40);

                let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.dataFields.category = "key";
                categoryAxis.renderer.minGridDistance = 1;
                categoryAxis.renderer.inversed = true;
                categoryAxis.renderer.grid.template.disabled = true;

                let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;

                let series = this.chart.series.push(new am4charts.ColumnSeries());
                series.dataFields.categoryY = "key";
                series.dataFields.valueX = "value";
                series.tooltipText = "{valueX.value}"
                series.columns.template.strokeOpacity = 0;
                series.columns.template.column.cornerRadiusBottomRight = 5;
                series.columns.template.column.cornerRadiusTopRight = 5;

                let labelBullet = series.bullets.push(new am4charts.LabelBullet())
                labelBullet.label.horizontalCenter = "left";
                labelBullet.label.dx = 10;
                labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#,###')}";
                labelBullet.locationX = 1;
                labelBullet.label.truncate = false;
                labelBullet.label.hideOversized = false;

                // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
                series.columns.template.adapter.add("fill", (fill, target) => {
                    return this.chart.colors.getIndex(target.dataItem.index);
                });

                this.chart.cursor = new am4charts.XYCursor();
                this.chart.exporting.menu = new am4core.ExportMenu();

                categoryAxis.sortBySeries = series;
            }
        }
    }
</script>

<style scoped>

</style>
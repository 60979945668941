<template>
    <div class="chart--generator">
        <h2> {{ toCapitalizedWords(title) }} </h2>

        <div class="row">
            <div class="col-md-4">
                <p style="font-size: 12px;">Wait up to 1 Second for changes in predictions</p>
                <p><b>{{ Object.keys(preProcessedData).length }}</b> data will be shown</p>
                <p><b>{{ Object.keys(data).length - Object.keys(preProcessedData).length }}</b> data will be hidden/others</p>
            </div>
            <div class="col-md-4" style="display: flex; flex-direction: column; align-items:center; justify-content:center">
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" :id="'check'+title" v-model="showOthers">
                  <label class="form-check-label" :for="'check'+title">Show Others</label>
                </div>
                <button class="btn btn-outline-primary" @click="modal.table = true">View All Data</button>

                <div class="row mt-3">
                    <div class="col" v-for="singleChart in chartOptions">
                        <div
                            class="chartSelectionItem"
                            :class="{'selected':chartSelected==singleChart.name}"
                            :title="toCapitalizedWords(singleChart.name)"
                            @click="chartSelected = singleChart.name"
                        >
                            <i class="fas" :class="[singleChart.icon]"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col">
                        <label :for="'excludeMax'+title" class="form-label">Exclude Data Under</label>
                        <input type="number" class="form-control" :id="'excludeMax'+title" v-model="excludeMax">
                        <div class="form-text" v-if="excludeMax != 0">Under {{ excludeMax }} value, data will be sorted into 'Others'</div>
                        <div class="form-text" v-else>No data under certain value will be excluded'</div>
                    </div>
                    <div class="col">
                        <label :for="'excludeMin'+title" class="form-label">Exclude Data Over</label>
                        <input type="number" class="form-control" :id="'excludeMin'+title" v-model="excludeMin">
                        <div class="form-text" v-if="excludeMin != 0">Over {{ excludeMin }} value, data will be sorted into 'Others'</div>
                        <div class="form-text" v-else>No data over certain value will be excluded'</div>
                    </div>
                </div>
                <div>
                    <label :for="'excludeKeyWord'+title" class="form-label mt-3">Exclude Key Word</label>
                    <input type="text" class="form-control" :id="'excludeKeyWord'+title" v-model="excludeKeyWord" placeholder="E.g: undefined;null">
                    <div class="form-text">Use ';'(Semi-colon) as separator for multiple</div>
                </div>
            </div>
        </div>

        <template v-if="readyForChartToBeGenerated">
            <!-- CHART START -->
            <bar-chart
                v-if="chartSelected == 'barChart' && processedData"
                :data="processedData"
            ></bar-chart>
            <column-chart
                v-if="chartSelected == 'columnChart' && processedData"
                :data="processedData"
            ></column-chart>
            <pie-chart
                v-if="chartSelected == 'pieChart' && processedData"
                :data="processedData"
            ></pie-chart>
            <!-- CHART END -->
            <!-- <div style="min-height: 400px; display: flex; align-items: center; justify-content: center;" v-else>
                <p style="margin: 0; font-size: 40px; text-shadow: 2px 2px 2px grey">Loading...</p>
            </div> -->
            <div class="progress" v-else>
                <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{'width': currentProgress/data.length+'%'}"
                    :aria-valuenow="currentProgress"
                    aria-valuemin="0"
                    :aria-valuemax="data.length"
                ></div>
            </div>
        </template>
        <template v-else>
            <div style="height: 300px; width: 100%; display: flex; justify-content: center; align-items: center;">
                <button
                    class="btn btn-primary"
                    @click="userReadyForData()"
                >Generate Chart</button>
            </div>
        </template>

        <!-- Modal Start -->
        <table-modal
            v-if="modal.table"
            :data="Object.entries(data).map(([key, count]) => ({key,count})).sort((a, b) => b.count - a.count)"
            @closeModal="modal.table = false"
        ></table-modal>
        <!-- Modal End -->

    </div>
</template>

<script>
    import debounce from 'debounce';
    
    import tableModal from './tableModal';

    import barChart from './charts/barChart';
    import columnChart from './charts/columnChart';
    import pieChart from './charts/pieChart';

    export default {
        data () {
            return {
                modal : {
                    table : false,
                },
                
                chartOptions : [
                    {name:'barChart', icon:'fa-abacus'},
                    {name:'columnChart', icon:'fa-chart-bar'},
                    {name:'pieChart', icon:'fa-chart-pie'},
                ],
                chartSelected : 'barChart',

                preProcessedData : [],
                processedData : null,

                excludeMax : 0,
                excludeMin : 0,
                excludeKeyWord : "",

                showOthers : true,
                readyForChartToBeGenerated : false,
                currentProgress : 0,
            }
        },
        props : ['title', 'data'],
        components : {
            tableModal,

            barChart,
            columnChart,
            pieChart,
        },
        watch : {
            'data' : {
                deep : true,
                handler () {
                    if (this.readyForChartToBeGenerated) this.generateData();
                }
            },
            async 'showOthers' () {
                if (this.readyForChartToBeGenerated) {
                    this.preProcessedData = await this.processData();
                    this.generateData();
                }
            },

            'excludeMax' () {
                this.readyForChartToBeGenerated = false;
                if (parseInt(this.excludeMax) < 0) this.excludeMax = 0;
                this.debounceProcessData();
            },
            'excludeMin' () {
                this.readyForChartToBeGenerated = false;
                if (parseInt(this.excludeMax) < 0) this.excludeMax = 0;
                this.debounceProcessData();
            },
            'excludeKeyWord' () {
                this.readyForChartToBeGenerated = false;
                if (parseInt(this.excludeMax) < 0) this.excludeMax = 0;
                this.debounceProcessData();
            },

            'chartSelected' () {
                this.readyForChartToBeGenerated = false;
            }
        },
        async mounted () {
            this.preProcessedData = await this.data;
        },
        methods : {
            userReadyForData () {
                if (Object.keys(this.preProcessedData).length > 35) {
                    this.$swal.fire({
                        title : 'Error!',
                        text: 'Data shown must not exceed 35 items!',
                        icon : 'warning'
                    })
                } else {
                    this.readyForChartToBeGenerated = true;
                    this.generateData();
                }
            },
            generateData () {
                this.processedData = this.preProcessedData;
            },
            debounceProcessData: debounce(async function (e) {
                this.preProcessedData = await this.processData();
            }, 1000),
            // debounceProcessData : debounce(async (e)=>{
            //     this.preProcessedData = await this.processData();
            // }, 1500),
            processData () {
                //Reset Data
                this.preProcessedData = [];
                this.processedData = null;
                this.currentProgress = 0;
                let others = 0;
                let data = {};

                let excludeKeyWord = this.excludeKeyWord.split(";");

                for (var i in this.data) {
                    this.currentProgress++;
                    let dataIncluded = false;

                    if (this.data[i] > parseInt(this.excludeMax)){
                        //If Over Than is activated
                        if (this.excludeMin != 0) {
                            if (this.data[i] < parseInt(this.excludeMin)) dataIncluded = true;
                            else dataIncluded = false;
                        } else dataIncluded = true;
                    } else dataIncluded = false;

                    //Exclude Key Word
                    if (excludeKeyWord.indexOf(i) != -1) dataIncluded = false;

                    //Tally up
                    if (dataIncluded) data[i] = this.data[i];
                    else others+=this.data[i];
                }
                if (others != 0 && this.showOthers) {
                    // console.log('hi');
                    data['Others'] = others;
                }

                return data;
            },
        }
    }
</script>

<style>
    .chart--generator *{
        color: black;
    }
</style>

<style scoped>
    .chart--generator {
        /* margin-top: 30px;
        padding-top: 30px;
        border-top: 20px solid grey; */
        padding: 30px;
        margin: 30px 0;

        border-radius: 15px;
        box-shadow: 0px 2px 10px #0000001A;
    }

    .chartSelectionItem {
        width: 50px;
        height: 50px;
        border-radius: 1000px;
        border:1px solid grey;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: background 0.5s ease;
    }

    .chartSelectionItem:not(.selected) {
        cursor: pointer;
    }

    .chartSelectionItem.selected {
        background:black;
    }

    .chartSelectionItem i {
        color: grey;
        font-size: 20px;
    }

    .chartSelectionItem.selected i {
        color: white;
    }
</style>
<template>
    <div class="contact-full-background" style="position: relative;">

        <div class="custom-shape-divider-top-1651346683">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
        </div>

        <div id="contact">
            <div class="container">
                <h1>Don't be a stranger!</h1>

                <div style="display: flex; justify-content: center;">
                    <div class="socMedContainer" :class="{'socMedContainerMobile' : mobile}">
                        <a href="https://www.linkedin.com/in/andre-valentino/" target="_blank">
                            <div class="socMedButton">
                                <i class="fab fa-linkedin"></i>
                            </div>
                        </a>
                        <a href="mailto:andre.valentino47@gmail.com" target="_blank">
                            <div class="socMedButton">
                                <i class="fas fa-envelope"></i>
                            </div>
                        </a>
                        <a href="https://wa.me/+6281806344894?text=Hey%21%20I%20need%20to%20talk%20to%20you%20about%20..." target="_blank">
                            <div class="socMedButton">
                                <i class="fab fa-whatsapp"></i>
                            </div>
                        </a>
                        <a href="https://www.instagram.com/andrevalentino3/" target="_blank">
                            <div class="socMedButton">
                                <i class="fab fa-instagram"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex; flex-direction: row-reverse;">
            <p style="font-size: 70%; margin: 0 10px 0 0">Made with ❤ by Andre Valentino (2023)</p>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile : this.isMobile(),
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    .custom-shape-divider-top-1651346683 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1651346683 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 100px;
    }

    .custom-shape-divider-top-1651346683 .shape-fill {
        fill: #7F7F7F;
    }

    .contact-full-background {
        background-color: #494949;
    }

    #contact {
        padding: 10em 0 3em;
        z-index: 2;
    }

    .socMedContainer {
        width: 70%;
        padding: 75px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .socMedContainerMobile {
        flex-direction: column;
        align-items: center;
        padding: 25px 0;
    }
    
    .socMedContainer a {
        text-decoration: none;
    }
    
    .socMedButton {
        height: 85px;
        width: 85px;
        border-radius: 20000px;

        cursor: pointer;
        /* margin: 0 40px; */
        background-color: white;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .socMedButton i {
        color: #494949;
        font-size: 3em;
    }

    .socMedContainerMobile>a>.socMedButton {
        margin: 20px 0;
    }

    #contact a {
        text-align: center;
    }

    #contact a:hover {
        text-decoration: none;
    }
</style>
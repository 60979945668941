<template>
    <div style="position: relative;">

        <!-- <div class="transition-background"></div> -->
        <div class="custom-shape-divider-top-1651342705">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
        </div>

        <div id="gallery">
            <div class="container">
                <h2 class="section--title">My Story</h2>

                <div class="row">
                    <div class="col-md-6 video-box" v-for="video in videoList">
                        <iframe
                            :width="mobile ? '100%' : '560'"
                            :height="mobile ? '100%' : '315'"
                            :src="'https://www.youtube.com/embed/' + video.video"
                            :title="video.title"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                        <h4>{{ video.title }}</h4>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile : this.isMobile(),
                videoList : [
                    {"title":"Halls Gap 29-30 April 2018", "video":"Uds_Lavl0YU"},
                    {"title":"Lembang 9-10 December 2017", "video":"0oIHJIdqN4k"},
                    {"title":"Europe Trip 17 June-1 July 2017", "video":"k_Zi9018u1s"},
                    {"title":"Semarang-Jogja 25-29 December 2016", "video":"tjpM9NwL32k"},
                    {"title":"Bandung 26-27 April 2016", "video":"oAg5bCAP0Aw"},
                ]
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    /* Background Transition Start */
    .custom-shape-divider-top-1651342705 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-top-1651342705 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 168px;
    }

    .custom-shape-divider-top-1651342705 .shape-fill {
        fill: #FFFFFF;
    }
    /* Background Transition End */

    #gallery {
        padding: 5em 0 0;
        z-index: 2;
    }

    .video-box {
        margin-bottom: 70px;
    }
</style>
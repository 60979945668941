<template>
    <div style="position: relative;">
        <div id="landing">
            <div class="landingContent">
                <i class="fad fa-laptop-code fa-7x"></i>
                <h1>Andre Valentino</h1>
                <p class="jobTitle">{{currJobTitle}}<span style="color: transparent;">.</span></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                jobTitles : ['Senior Front-end Developer', 'Mobile App Developer', 'Full-stack Web Developer', 'Freelancer'],
                // currJobTitle : 'Senior Front-end Developer',
                currJobTitle : '',
            }
        },
        mounted () {
            this.startJobTitleCycle();
        },
        methods : {
            async startJobTitleCycle () {
                function sleep (milliseconds) {
                    return new Promise(resolve => setTimeout(resolve, milliseconds));
                }

                let counter = 0;

                while (true) {
                    counter++;
                    if (counter % 10 == 0) this.jobTitles.push('Gamer 🎮');
                    for (let i in this.jobTitles) {
                        //Increase character by character
                        for (let j = 0; j <= this.jobTitles[i].length; j++) {
                            await sleep(100);
                            this.currJobTitle =  `${this.jobTitles[i].slice(0, j)}|`;
                        }

                        //Random wait 1-3 blinks
                        for (let l = 0; l < this.randomInt(1, 4); l++) {
                            await sleep(300);
                            this.currJobTitle =  `${this.jobTitles[i]} `;
                            await sleep(300);
                            this.currJobTitle =  `${this.jobTitles[i]}|`;
                        }

                        //Decrease character by character
                        for (let j = this.jobTitles[i].length; j >= 0; j--) {
                            await sleep(75);
                            this.currJobTitle =  `${this.jobTitles[i].slice(0, j)}|`;
                        }

                        //Random wait 2-5 blinks
                        for (let k = 0; k < this.randomInt(2, 6); k++) {
                            await sleep(300);
                            this.currJobTitle = ' ';
                            await sleep(300);
                            this.currJobTitle = '|';
                        }
                    } 
                    if (counter % 10 == 0) this.jobTitles.pop();
                } 
            },
        }
    }
</script>

<style scoped>
    #landing {
        background: #494949;
        color: white;
    
        /* width: calc(100vw - 0.75rem); */
        height: 100vh;

        /* position: absolute; */
    
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
    #landing>.landingContent>h1 {
        font: normal normal 500 2.8rem/3.5rem Rock Salt;
        margin: 1em 0 0.4em;
    }
  
    #landing>.landingContent>.jobTitle {
        font: normal normal 500 2em/1em Noto Sans Mono;
    }
</style>
<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="$emit('closeModal')">
                <div class="row">
                    <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
                    <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8">
                        <div class="modal-container" @click.stop>

                            <div class="modal-header" style="justify-content: space-between;">
                                <button class="btn btn-outline-info" @click="exportExcel(data)">
                                    Export To XLSX
                                </button>
                                <button type="button" class="btn" @click="$emit('closeModal')">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>

                            <div class="modal-body">
                                
                                <vue-universal-table
                                    :data="data"
                                ></vue-universal-table>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        props : ['data'],
        mounted() {

        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 1020;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 100%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
      * The following styles are auto-applied to elements with
      * transition="modal" when their visibility is toggled
      * by Vue.js.
      *
      * You can easily play with the modal transition by editing
      * these styles.
      */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    /* MODAL STARTS */
    .modal-body {
        margin: 0;
        max-height: 80vh;
        overflow-y: auto;
    }

    .modal-body .moduleLine {
        margin-bottom: 0vh;
    }

    .modal-body .moduleLine a {
        text-decoration: none;
    }

    .modal-body .moduleLine .moduleBox:hover {
        background-color: #E0E0E0;
        transition: background-color 0.7s ease;
        color: #212121;
        transition: color 0.7s ease;
    }

    .modal-body .moduleLine .moduleBox {
        border: 1px solid #BDBDBD;
        border-radius: 20px;
        padding: 20px 0;
        color: black;
        margin-bottom: 3vh;
    }

    .modal-body .moduleLine .moduleBox i {
        font-size: 5vh;
    }

    h3.modalHeadline {
        margin: 0 0 20px 0;
        font-weight: bold;
        text-align: center;
    }

    /* MODAL ENDS */

    /* ==========================================================================
    #FORM made by Colorlib (https://colorlib.com)
    ========================================================================== */
    input {
        outline: none;
        margin: 0;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 100%;
        font-size: 14px;
        font-family: inherit;
    }

    .input--style-4 {
        line-height: 50px;
        background: #fafafa;
        -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 0 20px;
        font-size: 16px;
        color: #666;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .input--style-4::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #666;
    }

    .input--style-4:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #666;
        opacity: 1;
    }

    .input--style-4::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #666;
        opacity: 1;
    }

    .input--style-4:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #666;
    }

    .input--style-4:-ms-input-placeholder {
        /* Microsoft Edge */
        color: #666;
    }

    .label {
        font-size: 16px;
        color: #555;
        text-transform: capitalize;
        display: block;
        margin-bottom: 5px;
    }

    .radio-container {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        font-size: 16px;
        color: #666;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .radio-container input:checked~.checkmark {
        background-color: #e5e5e5;
    }

    .radio-container input:checked~.checkmark:after {
        display: block;
    }

    .radio-container .checkmark:after {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #57b846;
    }

    .checkmark {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #e5e5e5;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .input-group {
        position: relative;
        /* margin-bottom: 22px; */
        padding: 20px;
    }

    .input-group-icon {
        position: relative;
    }

    .input-icon {
        position: absolute;
        font-size: 18px;
        color: #999;
        right: 18px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
    }

    /* ==========================================================================
       #TITLE made by Colorlib (https://colorlib.com)
       ========================================================================== */
    .title {
        font-size: 40px;
        color: #525252;
        /* margin-bottom: 40px; */
        text-align: center;
    }

    .sub--title {
        color: red;
        margin-bottom: 40px;
        text-align: center;
    }
</style>